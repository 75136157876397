import {
    Box,
    Grid,
    Button,
    Typography,
    useTheme,
    Backdrop,
    CircularProgress,
    Divider,
} from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import axiosClient from "../../../axios";
import { React, useState, useEffect, useCallback } from "react";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from '@mui/material/CardActions';
import RefreshIcon from "@mui/icons-material/Refresh";
import SingleSelect from "../../../components/SingleSelect";
import { FaFileExcel } from "react-icons/fa";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default function SummaryReports() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { RangePicker } = DatePicker;
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [refreshDatesRange, setRefreshDatesRange] = useState([]);
    const [inspections, setInspections] = useState([]);
    const [filteredInspections, setFilteredInspections] = useState([]); // Filtered inspections based on broker
    const [loadedInspections, setLoadedInspections] = useState([]);
    const [filteredLoadedInspections, setFilteredLoadedInspections] = useState([]);
    const [brokerList, setBrokerList] = useState([]);
    const [selectedBroker, setSelectedBroker] = useState(null);
    const [alertText, setAlertText] = useState(null);
    const [reqInspectionsAudits, setReqInspectionsAudits] = useState([]);
    const [reqLoadedInspectionsAudits, setReqLoadedInspectionsAudits] = useState([]);
    const [inspectionsReport, setInspectionsReport] = useState([]);
    const [loadedInspectionsReport, setLoadedInspectionsReport] = useState([]);

    const handleRefreshDatesRange = (dates) => {
        setRefreshDatesRange(dates);
    };

    const refreshInspections = async () => {
        setBackdropOpen(true);
        setAlertText(null);
        // Get all inspections that have been sent to broker
        const getInspections = async () => {
            const response = await axiosClient.get(
                `/inspections/summary_reports_inspections/${dayjs(
                    refreshDatesRange?.[0]
                ).format("YYYY-MM-DD")}/${dayjs(
                    refreshDatesRange?.[1]
                ).format("YYYY-MM-DD")}`
            );

            if (response.data.length > 0) {
                setInspections(response.data);
                setFilteredInspections(response.data);
                populateRequirementsAudits(Object.values(response.data));
                setAlertText('All brokers')
                // console.log("Inspections: ", response.data);
                // Create start and end dates for the date range picker, first array element is start date, last is end date
                const startDate = dayjs(response.data[0].reminder_start_date);
                const endDate = dayjs(response.data[response.data.length - 1].reminder_start_date);
                setRefreshDatesRange([startDate, endDate]);
            }
        };

        // Get all loaded inspections
        const getLoadedInspections = async () => {
            const response = await axiosClient.get(
                `/inspections/get_new_inspections/${dayjs(
                    refreshDatesRange?.[0]
                ).format("YYYY-MM-DD")}/${dayjs(
                    refreshDatesRange?.[1]
                ).format("YYYY-MM-DD")}`
            );

            if (response.data.length > 0) {
                setLoadedInspections(response.data);
                setFilteredLoadedInspections(response.data);
                populateLoadedRequirementsAudits(Object.values(response.data));
                // console.log("LoadedInspections: ", response.data);
            }
        };
        
        // Wait for both getInspections and getLoadedInspections to complete
        await Promise.all([getInspections(), getLoadedInspections()]);

        setBackdropOpen(false);
    };

    // Update the list of brokers based on inspections loaded
    useEffect(() => {
        // console.log("Updating filtered inspections")
        const broker_list = [];
        for (const key in inspections) {
            // Store broker names into broker_list
            broker_list.push(inspections[key].audit_broker_name);
        }
        // Create unique broker list
        const unique_brokers = [...new Set(broker_list)];
        const brokersObject = unique_brokers.map(broker => ({ label: broker, value: broker }));
        brokersObject.unshift({ label: 'All', value: 'All' });
        // console.log("Unique Brokers: ", brokersObject);
        setBrokerList(brokersObject);
    }, [inspections]);

    // Function to populate reqInspectionsAudits with audit_id values from an array of inspections
    const populateRequirementsAudits = (inspectionsArray) => {
        const audits = inspectionsArray.map(inspection => inspection.audit_id);
        setReqInspectionsAudits(audits);
        // console.log("Requirements Audits: ", audits);
    };
    const populateLoadedRequirementsAudits = (inspectionsArray) => {
        const audits = inspectionsArray.map(inspection => inspection.audit_id);
        setReqLoadedInspectionsAudits(audits);
        // console.log("Requirements Audits: ", audits);
    };

    // Update the list of filteredInspections at broker-level based on which broker selected
    useEffect(() => {
        // console.log("Broker changing to: ", selectedBroker);
        if (selectedBroker !== null) {
            if (selectedBroker === 'All') {
                setFilteredInspections(inspections);
                setFilteredLoadedInspections(loadedInspections);
                populateRequirementsAudits(Object.values(inspections));
                populateLoadedRequirementsAudits(Object.values(loadedInspections));
                setAlertText('All brokers')
            } else {
                const filterInspections = Object.values(inspections).filter(
                    (inspection) => {
                        return inspection.audit_broker_name === selectedBroker;
                    }
                );
                const filterLoadedInspections = Object.values(loadedInspections).filter(
                    (inspection) => {
                        return inspection.audit_broker_name === selectedBroker;
                    }
                );
                setFilteredInspections(filterInspections);
                setFilteredLoadedInspections(filterLoadedInspections);
                populateRequirementsAudits(filterInspections);
                populateLoadedRequirementsAudits(filterLoadedInspections);
                setAlertText(selectedBroker + ' broker')
            }
        }
    }, [selectedBroker]);

    ////////////////////////////////////// Load data for reporting ///////////////////////////////////////////////// 
    useEffect(() => {
        const fetchData = async () => {
            setBackdropOpen(true);

            const promises = [];

            if (reqLoadedInspectionsAudits.length !== 0) {
                const promise = axiosClient.post('/inspections/get_loaded_report_inspections', { ids: reqLoadedInspectionsAudits })
                    .then(response => {
                        if (response.data) {
                            response.data.forEach(inspection => {
                                inspection.requirements_count = inspection.requirements.length;
                            });
                            // console.log("Loaded Inspections Report: ", response.data);
                            setLoadedInspectionsReport(response.data);
                        }
                    });
                promises.push(promise);
            }

            if (reqInspectionsAudits.length !== 0) {
                const promise = axiosClient.post('/inspections/get_all_report_inspections', { ids: reqInspectionsAudits })
                    .then(response => {
                        if (response.data) {
                            // console.log("Inspections Report: ", response.data);
                            setInspectionsReport(response.data);
                        }
                    });
                promises.push(promise);
            }

            await Promise.all(promises);

            setBackdropOpen(false);
        };

        fetchData();
    }, [reqInspectionsAudits, reqLoadedInspectionsAudits]);

    //////////////////////////////////////// Export Data //////////////////////////////////////////////////
    
        const exportAllRequirements = () => {
            if (inspectionsReport.length === 0) {
                window.alert("All requirements has no data to export");
                return;
            }
            const allRequirements = inspectionsReport['all_requirements']['all_requirements'];

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileName = 'All_Requirements.xlsx';

            const data_list = allRequirements
                .filter(requirement => ['Completed', 'Pending', 'In progress', 'To be attended'].includes(requirement.requirement_status))
                .map(requirement => ({
                    'Portal account status': requirement.audit_account_status,
                    'Policy number': requirement.audit_policy_number,
                    'Policyholder': requirement.audit_policy_holder,
                    'Broker name': requirement.audit_broker_name,
                    'Requirement details': requirement.requirement_details,
                    'Requirement status': requirement.requirement_status,
                    'Survey date': dayjs(requirement.survey_date).format("YYYY-MM-DD"),
                    'Underwriter email': requirement.audit_insurer_email,
                    'Fetched on': dayjs(requirement.created_at).format("YYYY-MM-DD"),
                    'Emailed to broker on': dayjs(requirement.reminder_start_date).format("YYYY-MM-DD"),
                    'Complete by': dayjs(requirement.expected_date).format("YYYY-MM-DD"),
                }));
            // console.log("All Data List: ", data_list);

            const ws = XLSX.utils.json_to_sheet(data_list);
            const wb = { Sheets: { 'Inspections': ws }, SheetNames: ['Inspections'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName);
        }

        const exportOutRequirements = () => {
            if (inspectionsReport.length === 0) {
                window.alert("Outstanding requirements has no data to export");
                return;
            }
            const outRequirements = inspectionsReport['outstanding_requirements']['outstanding_requirements'];
            // console.log("Outstanding:", outRequirements);

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileName = 'Outstanding_Requirements.xlsx';

            const data_list = outRequirements
                .filter(requirement => ['Pending', 'In progress', 'To be attended'].includes(requirement.requirement_status))
                .map(requirement => ({
                    'Portal account status': requirement.audit_account_status,
                    'Policy number': requirement.audit_policy_number,
                    'Policyholder': requirement.audit_policy_holder,
                    'Broker name': requirement.audit_broker_name,
                    'Requirement details': requirement.requirement_details,
                    'Requirement status': requirement.requirement_status,
                    'Survey date': dayjs(requirement.survey_date).format("YYYY-MM-DD"),
                    'Underwriter email': requirement.audit_insurer_email,
                    'Fetched on': dayjs(requirement.created_at).format("YYYY-MM-DD"),
                    'Emailed to broker on': dayjs(requirement.reminder_start_date).format("YYYY-MM-DD"),
                    'Complete by': dayjs(requirement.expected_date).format("YYYY-MM-DD"),
                }));
            // console.log("Outstanding Data List: ", data_list);

            const ws = XLSX.utils.json_to_sheet(data_list);
            const wb = { Sheets: { 'Inspections': ws }, SheetNames: ['Inspections'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName);
        }

        const exportDueRequirements = () => {
            if (inspectionsReport.length === 0) {
                window.alert("Overdue requirements has no data to export");
                return;
            }
            const dueRequirements = inspectionsReport['overdue_requirements']['overdue_requirements'];
            // console.log("Overdue:", dueRequirements);

            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileName = 'Overdue_Requirements.xlsx';

            const data_list = dueRequirements
                .filter(requirement => ['Pending', 'In progress', 'To be attended'].includes(requirement.requirement_status))
                .map(requirement => ({
                    'Portal account status': requirement.audit_account_status,
                    'Policy number': requirement.audit_policy_number,
                    'Policyholder': requirement.audit_policy_holder,
                    'Broker name': requirement.audit_broker_name,
                    'Requirement details': requirement.requirement_details,
                    'Requirement status': requirement.requirement_status,
                    'Survey date': dayjs(requirement.survey_date).format("YYYY-MM-DD"),
                    'Underwriter email': requirement.audit_insurer_email,
                    'Fetched on': dayjs(requirement.created_at).format("YYYY-MM-DD"),
                    'Emailed to broker on': dayjs(requirement.reminder_start_date).format("YYYY-MM-DD"),
                    'Complete by': dayjs(requirement.expected_date).format("YYYY-MM-DD"),
                }));
            // console.log("Overdue Data List: ", data_list);

            const ws = XLSX.utils.json_to_sheet(data_list);
            const wb = { Sheets: { 'Inspections': ws }, SheetNames: ['Inspections'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName);
        }

        const exportLoadedInspections = () => {
            if (loadedInspectionsReport.length === 0) {
                window.alert("Loaded Inspections has no data to export");
                return;
            }
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const fileName = 'Surveys_Completed.xlsx';

            const data_list = loadedInspectionsReport.flatMap(report => 
                report.requirements.map(requirement => ({
                    'Portal account status': report.audit_account_status,
                    'Policy number': report.audit_policy_number,
                    'Policyholder': report.audit_policy_holder,
                    'Broker name': report.audit_broker_name,
                    'Requirement details': requirement.requirement_details,
                    'Underwriter email': report.audit_insurer_email,
                    'Survey date': dayjs(report.audit_survey_date).format("YYYY-MM-DD"),
                    'Fetched on': dayjs(requirement.updated_at).format("YYYY-MM-DD"),
                }))
            );
            // console.log("Loaded Data List: ", data_list);

            const ws = XLSX.utils.json_to_sheet(data_list);
            const wb = { Sheets: { 'Inspections': ws }, SheetNames: ['Inspections'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName);
        }

    return (
        <Box marginBottom={2}>
            <Header subtitle="Summary reports on requirements statuses" />

            {/* ************************************ Date Range and Broker filters **************************************** */}
            <Box sx={{ marginTop: 1, display: "flex" }}>
                <RangePicker
                    allowClear={false}
                    value={
                        refreshDatesRange.length > 0
                            ? refreshDatesRange
                            : ""
                    }
                    onChange={handleRefreshDatesRange}
                    showTime={false}
                />
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={refreshInspections}
                    sx={{ marginLeft: 2 }}
                >
                    Reload
                </Button>
                <Box sx={{ flexGrow: 1, maxWidth: "20%", marginLeft: 2 }}>
                    <SingleSelect
                        options={brokerList}
                        // Find the array index of selectedBroker in brokers array and set it as selectedValueIndex
                        selectedValueIndex={brokerList?.findIndex(
                            (broker) => broker?.value === selectedBroker
                        )}
                        onSelectedValue={setSelectedBroker}
                        placeholder="Select Broker..."
                    />
                </Box>

                {alertText && alertText !== "" && (
                    <Alert severity="info" sx={{ width: '40vw', marginLeft: 2, padding: 0 }}>
                        Reports based on <strong>{alertText}</strong>
                    </Alert>
                )}
            </Box>

            {/* ******************************** Cards for dispaying summary reports *************************************** */}
            <Grid container spacing={2} marginTop={0}>
                <Grid item xs={3}>
                <Card sx={{ bgcolor: colors.primary[400], display: 'flex', flexDirection: 'column', minWidth: 150, minHeight: 238 }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography color="text.secondary" variant="h5">
                        All Requirements
                        </Typography>
                        <Stack direction="row" spacing={6}>
                            <Box>
                                <List sx={{ paddingLeft: 2 }}>
                                    <ListItem disablePadding>
                                        <ListItemText primary={`Completed: ${inspectionsReport?.all_requirements?.completed || 0}`} />
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemText primary={`Pending: ${inspectionsReport?.all_requirements?.pending || 0}`} />
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemText primary={`In progress: ${inspectionsReport?.all_requirements?.in_progress || 0}`} />
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemText primary={`To be attended: ${inspectionsReport?.all_requirements?.to_be_attended || 0}`} />
                                    </ListItem>
                                </List>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography color="text.secondary" variant="h1">
                                    {
                                        (inspectionsReport?.all_requirements?.completed || 0) +
                                        (inspectionsReport?.all_requirements?.pending || 0) + 
                                        (inspectionsReport?.all_requirements?.in_progress || 0) + 
                                        (inspectionsReport?.all_requirements?.to_be_attended || 0)
                                    }
                                </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Button size="small" variant="outlined" onClick={exportAllRequirements}><FaFileExcel /> Export</Button>
                    </CardActions>
                </Card>
                </Grid>
                <Grid item xs={3}>
                <Card sx={{ bgcolor: colors.primary[400], display: 'flex', flexDirection: 'column', minWidth: 150, minHeight: 238 }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography color="text.secondary" variant="h5">
                        Outstanding Requirements
                        </Typography>
                        <Stack direction="row" spacing={6} marginTop={3}>
                            <Box>
                                <List sx={{ paddingLeft: 2 }}>
                                    <ListItem disablePadding>
                                        <ListItemText primary={`Pending: ${inspectionsReport?.outstanding_requirements?.pending || 0}`} />
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemText primary={`In progress: ${inspectionsReport?.outstanding_requirements?.in_progress || 0}`} />
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemText primary={`To be attended: ${inspectionsReport?.outstanding_requirements?.to_be_attended || 0}`} />
                                    </ListItem>
                                </List>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography color="text.secondary" variant="h1">
                                    {
                                        (inspectionsReport?.outstanding_requirements?.pending || 0) + 
                                        (inspectionsReport?.outstanding_requirements?.in_progress || 0) + 
                                        (inspectionsReport?.outstanding_requirements?.to_be_attended || 0)
                                    }
                                </Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Button size="small" variant="outlined" onClick={exportOutRequirements}><FaFileExcel /> Export</Button>
                    </CardActions>
                </Card>
                </Grid>
                <Grid item xs={3}>
                <Card sx={{ bgcolor: colors.primary[400], display: 'flex', flexDirection: 'column', minWidth: 150, minHeight: 238 }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography color="text.secondary" variant="h5">
                        Overdue Requirements
                        </Typography>
                        <Stack direction="row" spacing={6} marginTop={3}>
                            <Box>
                                <List sx={{ paddingLeft: 2 }}>
                                    <ListItem disablePadding>
                                        <ListItemText primary={`Pending: ${inspectionsReport?.overdue_requirements?.pending || 0}`} />
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemText primary={`In progress: ${inspectionsReport?.overdue_requirements?.in_progress || 0}`} />
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemText primary={`To be attended: ${inspectionsReport?.overdue_requirements?.to_be_attended || 0}`} />
                                    </ListItem>
                                </List>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography color="text.secondary" variant="h1">
                                    {
                                        (inspectionsReport?.overdue_requirements?.pending || 0) + 
                                        (inspectionsReport?.overdue_requirements?.in_progress || 0) + 
                                        (inspectionsReport?.overdue_requirements?.to_be_attended || 0)
                                    }
                                </Typography>
                            </Box>
                            </Stack>
                    </CardContent>
                    <CardActions>
                        <Button size="small" variant="outlined" onClick={exportDueRequirements}><FaFileExcel /> Export</Button>
                    </CardActions>
                </Card>
                </Grid>
                <Grid item xs={3}>
                <Card sx={{ bgcolor: colors.primary[400], display: 'flex', flexDirection: 'column', minWidth: 150, minHeight: 238 }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography color="text.secondary" variant="h5" sx={{textAlign: 'center'}}>
                        Surveys completed pending broker submission
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography color="text.secondary" variant="h2">
                            {reqLoadedInspectionsAudits.length}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography>
                            Surveys
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography color="text.secondary" variant="h2">
                            {
                                reqLoadedInspectionsAudits.length > 0 
                                ? loadedInspectionsReport.reduce((total, report) => 
                                    total + (Number.isFinite(report.requirements_count) ? report.requirements_count : 0), 0)
                                : 0
                            }
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography>
                            Requirements
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardActions>
                        <Button size="small" variant="outlined" onClick={exportLoadedInspections}><FaFileExcel /> Export</Button>
                    </CardActions>
                </Card>
                </Grid>
            </Grid>

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Divider sx={{ marginTop: 2 }} />
        </Box>
    );
}
